<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        For each of the reactions listed below, determine the change in the number of gaseous moles,
        <stemble-latex content="$\Delta \text{n:}$" />
      </p>

      <p class="mb-2">
        a)
        <chemical-latex content="(NH4)2CO3(s) <=> 2 NH3(g) + H2O(g) + CO2(g)" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        :prepend-text="'$\\Delta \\text{n:}$'"
        :append-text="'$\\text{mol}$'"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b)
        <chemical-latex content="H2(g) + Cl2(g) <=> 2 HCl(g)" />
      </p>
      <calculation-input
        v-model="inputs.input2"
        :prepend-text="'$\\Delta \\text{n:}$'"
        :append-text="'$\\text{mol}$'"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c)
        <chemical-latex content="2 H2(g) + O2(g) <=> 2 H2O(l)" />
      </p>
      <calculation-input
        v-model="inputs.input3"
        :prepend-text="'$\\Delta \\text{n:}$'"
        :append-text="'$\\text{mol}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question106',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    reactionNumber() {
      return this.taskState.getValueBySymbol('arrayVariable').content.value;
    },
    reaction() {
      if (this.reactionNumber === 0) {
        return '\\ce{A + 2B -> products} \\qquad rate=k[A]^2[B]^2';
      } else if (this.reactionNumber === 1) {
        return '\\ce{A + B -> products} \\qquad rate=k[A][B]^2';
      } else if (this.reactionNumber === 2) {
        return '\\ce{2A + 3B -> products} \\qquad rate=k[B]^2';
      } else if (this.reactionNumber === 3) {
        return '\\ce{3A + 2B -> products} \\qquad rate=k[A]^2[B]';
      } else if (this.reactionNumber === 4) {
        return '\\ce{A + B -> products} \\qquad rate=k[B]';
      } else if (this.reactionNumber === 5) {
        return '\\ce{3A + B -> products} \\qquad rate=k[A]^2';
      } else if (this.reactionNumber === 6) {
        return '\\ce{2A + B -> products} \\qquad rate=k[A][B]^2';
      } else {
        return '';
      }
    },
  },
};
</script>
